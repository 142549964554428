#myIcon
{
    cursor: pointer;
}

.custom2 {
    outline: none;
  }

.mine {
    background-color: #343a40 !important;
    color: white;
}

.mine-active {
    background-color: #343a40 !important;
    color: white;
    border-color: black !important;
}


.bsize {
    width: 190px !important;
    height: 100px !important;
}